.MuiStepLabel-label{
    color : white !important;
}

.stepper-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
}

.stepper-container {
    padding: 50px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #262954;
}

@media (max-width: 450px) {
    .stepper-container {
        height: 100%;
    }
}