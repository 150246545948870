.card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 1px solid #97c2fc;
    border-radius: 10px;
    color : white;
    width: 200px;
    height: 100px;
    padding: 10px;
    margin: 10px 10px 10px 0;

}

.card-list-container {
    display: flex;
    /*width: 100%;*/
    margin: 10px 0;
    overflow-x: auto;
}

.card-image {
    display: flex;
    justify-content: center;
    font-size: 75px;
}

.header {
    display: flex;
    width : 100%;
    align-items: center;
}