.neovis-container {
    display: flex;
    /*border : 1px solid white;*/
    height: 80%;
    width: 100%;
    padding-top: 10px;
}

.neovis-error{
    position: absolute;
    left: 25%;
    top: 45%;
    transform: translate(-50%, 50%);
}

.neovis-status-container {
    border : 1px solid white;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*#viz {*/
/*    border : 1px solid white;*/
/*    height: 100%;*/
/*    width: 50%;*/
/*}*/

.tooltip-container {
    background-color: #1976d2;
    margin: 0 10px;
    width :50%;
}

@media (max-width: 450px) {
    .neovis-container {
        flex-direction: column;
    }

    .neovis-status-container {
        height: 25vh;
        width: 100%;
    }

    #viz {
        height: 25vh;
        width: 100%;
    }

    .tooltip-container {
        height: 30vh;
        width: 100%;
        margin: 10px 0;
    }
}

.tooltip-table-container {
    width : 40%;
}

.tooltip-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% ;
}

.tooltip-data {
    border : none;
}