.input-element {
    display: flex;
    padding: 10px 0;
}

.camera-input {
    border-color: white;
    margin-top: 10px;
}

@media (max-width: 450px) {
    .input-element {
        flex-direction: column;
    }

    .camera-input {
        margin-left: 0;
        margin-top: 10px;
    }
}
