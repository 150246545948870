.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5%;
}

a {
    text-decoration: none;
    color : white;
}
.navbar-link {
    color : white;
    margin: 10px;
}

.navbar-link-active {
    background-color: #1976d2;
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 8px 5px rgba(25,118,210,0.29);
    box-shadow: 0px 0px 8px 5px rgba(25,118,210,0.29);}

.navbar-container-link {
    /*display: flex;*/

}